import { useState } from 'react';
import { retreivePassageText } from './core/DataLoader';
import { atBooksPromise, translationsPromise } from './core/DataStorage';
import Footer from './Footer';
import Header from './Header';
import { CustomText, isCustomText, isPassage, Passage, RowInput } from './model/RowInput';
import { OutputElement } from './model/OutputElement';
import { defaultSlideOptionsData } from './slides/SlideOptionsData';
import { Translation } from './model/Translation';
import EntryTable from './table/EntryTable';
import SlideOptions from './slides/SlideOptions';
import SlideResults from './slides/SlideResults';
import NotificationManager from './notification/NotificationManager';
import { NotificationContext, NotificationData, NotificationState, NotificationType } from './notification/NotificationContext';
import _ from 'lodash';

const App = () => {
  const [slideOptionsData, setSlideOptionsData] = useState(defaultSlideOptionsData);

  const addNotification = (e: NotificationData) => {
    notificationState.notifications.push(e)
    setNotificationState({
      notifications: notificationState.notifications,
      addNotification,
      removeNotification
    });
    setTimeout(() => {
      removeNotification(0);
    }, 5000);
  };

  const removeNotification = (i: number) => {
    notificationState.notifications.splice(i, 1);
    setNotificationState(_.cloneDeep(notificationState));
  };

  const [notificationState, setNotificationState] = useState<NotificationState>({
    notifications: [],
    addNotification,
    removeNotification
  });

  const [outputElements, setOutputElements] = useState<OutputElement[]>([]);
  const [atBooks, setAtBooks] = useState<string[]>([])
  const [allTranslations, setAllTranslations] = useState<Translation[]>([]);

  atBooksPromise.then(e => setAtBooks(e));
  translationsPromise.then(e => setAllTranslations(e));

  const passageOrError = async (e: Passage) => {
    const r = await retreivePassageText(e);
    if (r === undefined) {
      addNotification({
        type: NotificationType.Error,
        message: 'Cannot load passage! Please check the references.'
      });
      return 'Sorry! Something went wrong here.'
    }
    return r;
  };

  const retrieveTexts = (rowInputs: RowInput[]) => {
    for (let e of rowInputs) {
      if (isPassage(e) && !isValidContent(e as Passage)) {
        addNotification({ type: NotificationType.Error, message: 'A translations has no Old Testament available!' });
        return;
      }
    }
    const promises = rowInputs.map(e => isCustomText(e) ? e : passageOrError(e as Passage));
    Promise.all(promises).then(texts => {
      const oe = texts.map((e, i) => typeof e === 'string' ? ({ passage: rowInputs[i] as Passage, text: e }) : (e as CustomText));
      setOutputElements(oe);
    });
  };

  const isValidContent = (e: Passage) => {
    const entryShortName = e.translation.split('(')[1].replace(')', '');
    const translation = allTranslations.find(e => e.short_name === entryShortName);
    if (translation === undefined) {
      return false;
    }
    if (atBooks.includes(e.book)) {
      if (!translation.parts.includes('AT')) {
        return false;
      }
    }
    return true;
  };

  return (
    <div>
      <div className='relative min-h-screen'>
        <div className='pb-20'>
          <Header />
          <div className='container m-auto'>
            <EntryTable onUpdate={retrieveTexts} />
            <SlideOptions onUpdate={e => setSlideOptionsData(e)} />
            <NotificationContext.Provider value={notificationState}>
              {outputElements.length > 0 ? <SlideResults outputElements={outputElements} slideOptionsData={slideOptionsData} /> : null}
            </NotificationContext.Provider>
          </div>
        </div>
        <Footer />
      </div>
      <NotificationContext.Provider value={notificationState}>
        <NotificationManager />
      </NotificationContext.Provider>
    </div>
  );
};

export default App;
