import { useContext } from 'react';
import { formatForClipboard, preformatCustomText, preformatPassageText } from '../core/TextFormatter';
import { isPassageText, OutputElement, PassageText } from '../model/OutputElement';
import { CustomText } from '../model/RowInput';
import { SlideOptionsData } from './SlideOptionsData';
import { NotificationContext, NotificationType } from '../notification/NotificationContext';
import SlideText from './SlideText';
import SlideSeparator from './SlideSeparator';

interface Props {
  outputElements: OutputElement[];
  slideOptionsData: SlideOptionsData;
}

const SlideResults = (props: Props) => {
  const notificationState = useContext(NotificationContext);
  let preformattedTexts = props.outputElements.map(e => !isPassageText(e) ? preformatCustomText(e as CustomText, props.slideOptionsData) : preformatPassageText(e as PassageText, props.slideOptionsData))

  const copyToClipboard = () => {
    const r = formatForClipboard(preformattedTexts, props.slideOptionsData.referenceAbove);
    navigator.clipboard.writeText(r);
    notificationState.addNotification({
      type: NotificationType.Info,
      message: 'Text of all slides copied to clipboard.'
    });
  };

  const elements: JSX.Element[] = [];
  for (let pt of preformattedTexts) {
    elements.push(<SlideSeparator key={elements.length} label={pt.reference === '' ? (props.slideOptionsData.upperCase ? 'CUSTOM TEXT' : 'Custom Text') : pt.reference}></SlideSeparator>);
    for (let st of pt.slideTexts) {
      const refLine = props.slideOptionsData.referenceAbove ? pt.reference : '';
      const textLine = st.join('\n').trim();
      const c = refLine + (refLine !== '' ? '\n': '') + textLine;
      elements.push(<SlideText key={elements.length} content={c}></SlideText>);
    }
  }

  return (
    <div className='mt-5 p-5 border rounded'>
      <div className='flex'>
        <h2 className='mb-2 text-xl text-slate-900'>Slides:</h2>
        <div className='flex-grow'></div>
        <button className='px-2 bg-green-300 hover:bg-green-300 hover:bg-green-400 active:bg-green-500 text-white rounded' onClick={copyToClipboard}>{'\uD83D\uDCCB'}</button>
      </div>
      <div className='px-10 pb-10'>
        {elements}
      </div>
    </div>
  );
};

export default SlideResults;