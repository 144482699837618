import React from "react";

export enum NotificationType {
  Info,
  Warning,
  Error
}

export interface NotificationData {
  type: NotificationType,
  message: string
}

export interface NotificationState {
  notifications: NotificationData[],
  addNotification: (e: NotificationData) => void,
  removeNotification: (n: number) => void;
}

export const NotificationContext = React.createContext<NotificationState>({
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {}
});
