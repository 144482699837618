const Header = () => {
  return (
    <div className='flex p-5 justify-center bg-sky-600'>
        <h1 className='text-4xl font-light text-white'>
            Scriptures
        </h1>
    </div>
  );
};

export default Header;
