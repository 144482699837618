export interface SlideOptionsData {
  upperCase: boolean;
  referenceAbove: boolean;
  charactersPerLine: number;
  linesPerSlide: number;
  newSlideAfter: string[];
}

export const slideSplitSymbols = [',', '.', ';', ':', '!', '?', '"', '«'];

export const defaultSlideOptionsData: SlideOptionsData = {
  upperCase: true,
  referenceAbove: true,
  charactersPerLine: 55,
  linesPerSlide: 4,
  newSlideAfter: [...slideSplitSymbols],
};