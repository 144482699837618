import React from 'react';
import { useState } from 'react';

interface Props {
  text: string;
  regex: string;
  fixed: boolean;
  onTextAvailable: (e: string) => void;
}

const TextInput = (props: Props) => {
  const [text, setText] = useState(props.text ?? '');

  React.useEffect(() => {
    setText(props.text ?? '');
  }, [props.text])

  const onLeave = (e: string) => {
    if (new RegExp(props.regex).test(e)) {
      props.onTextAvailable(e);
    }
  };
  
  const onKeyEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onLeave(e.currentTarget.value);
    }
  };

  return (
    <div className='relative'>
      <input type='text' className={'w-full mt-1 pl-1 text-gray-700 border rounded focus:outline-none'} onChange={e => setText(e.currentTarget.value)} onBlur={e => onLeave(e.currentTarget.value)} onKeyDown={e => onKeyEvent(e)} value={text} />
    </div>
  );
};

export default TextInput;