import { useContext } from "react";
import { NotificationContext } from "./NotificationContext";
import InfoNotification from "./Notification";

const NotificationManager = () => {
  const notificationState = useContext(NotificationContext);
  const elements = notificationState.notifications.map((e, i) => <InfoNotification key={i} position={i} content={e.message} type={e.type} onClose={() => notificationState.removeNotification(i)}></InfoNotification>)
  return (
      <div>
        {elements}
      </div>
  );
};

export default NotificationManager;