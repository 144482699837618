import { PreformattedText } from "../model/PreformattedText";
import { OutputElement, PassageText } from "../model/OutputElement";
import { SlideOptionsData } from "../slides/SlideOptionsData";
import { CustomText } from "../model/RowInput";

export const formatForClipboard = (e: PreformattedText[], referenceAbove: boolean) => {
  let r = '';
  for (const pt of e) {
    const refLine = (referenceAbove ? pt.reference : '').trim();
    for (const st of pt.slideTexts) {
      r += refLine + (refLine !== '' ? '\n' : '');
      const textLine = st.join('').trim();
      r += textLine + (textLine !== '' ? '\n\n' : '\n');
    }
  }
  return r.trim();
};

const containsCharOf = (t: string, s: string[]) => {
  for (const c of s) {
    if (t.includes(c)) {
      return true;
    }
  }
  return false;
};

export const preformatCustomText = (e: CustomText, o: SlideOptionsData): PreformattedText => {
    return preformatText(e, e.reference, o);
}

export const preformatPassageText = (e: PassageText, o: SlideOptionsData): PreformattedText => {
  const p = `${e.passage.book} ${e.passage.chapter}${e.passage.chapter !== '' ? ',' : ''}${e.passage.verses} (${e.passage.translation.split('(')[1].replace(')', '')})`;
  return preformatText(e, p, o);
};

export const preformatText = (e: OutputElement, p: string, o: SlideOptionsData): PreformattedText => {
  p = o.upperCase ? p.toUpperCase() : p;
  let t = e.text
    .replace(/\[[a-zäöüA-ZÄÖÜ0-9 ]+\]/g, '')
    .replaceAll('  ', ' ')
    .replaceAll('⟨', '')
    .replaceAll('⟩', '')
    .trim();
  t = o.upperCase ? t.toUpperCase() : t;
  const splt = t.split(' ');
  let charCount = 0;
  let lineCount = 0;
  let r: string[][] = [];
  let s: string[] = [];
  let l = '';
  for (const i in splt) {
    const e = splt[i];
    charCount += e.length + 1;
    l += e.trim() + ' ';
    if (lineCount > o.linesPerSlide - 2) {
      if (containsCharOf(e, o.newSlideAfter) || charCount > o.charactersPerLine) {
        charCount = 0;
        lineCount = 0;
        s.push(l)
        l = '';
        r.push(s)
        s = [];
      }
    } else {
      if (charCount > o.charactersPerLine) {
        charCount = 0;
        lineCount++;
        if (Number(i) !== splt.length - 1) {
          s.push(l);
          l = '';
        }
      }
    }
  }
  if (l.length > 0) {
    s.push(l);
  }
  if (s.length > 0) {
    r.push(s);
  }
  return {
    reference: p,
    slideTexts: r
  };
};