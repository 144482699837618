import { REST_API } from '../env/config';
import { Passage } from '../model/RowInput';
import { Translation } from '../model/Translation';

export async function retrieveTranslations(): Promise<Translation[]> {
  const url = REST_API + '/translations';
  const response = await fetch(url);
  return await response.json();
}

export async function retrieveBooks(): Promise<string[]> {
  const url = REST_API + '/books';
  const response = await fetch(url);
  return await response.json();
}

export async function retrieveBooksAt(): Promise<string[]> {
  const url = REST_API + '/books-at';
  const response = await fetch(url);
  return await response.json();
}

export async function retrieveBooksNt(): Promise<string[]> {
  const url = REST_API + '/books-nt';
  const response = await fetch(url);
  return await response.json();
}

export async function retreivePassageText(e: Passage) {
  e = JSON.parse(JSON.stringify(e));
  e.translation = e.translation.split('(')[1].replace(')', '');
  const url = REST_API + '/scripture';
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(e)
    });
    return await response.text();
  } catch (error) {
    return undefined;
  }
}